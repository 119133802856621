import { memo, useState } from 'react'
import type { FC, Dispatch, SetStateAction } from 'react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { getTranslations } from '@/context'
import { fetchAPI, apiBrands } from '@/api'
import { HeadTag } from '@dy/commons/components'
import { LayoutEmpty, SectionStorePicker, HeaderHome, SectionBestseller, Subscribe, BrandsSection, SectionProductDetail } from '@/components'
import { TypeProductDetailVariant } from '@dy/commons/schema'
import type { ISectionHighlights } from '../components/home/SectionHighlights'
import type { ISectionMyProducts } from '../components/home/myProducts/SectionMyProducts'

const DynamicSectionHighlights = dynamic<ISectionHighlights>(() => import('../components/home/SectionHighlights').then((mod) => mod.SectionHighlights))
const DynamicSectionMyProducts = dynamic<ISectionMyProducts>(() => import('../components/home/myProducts/SectionMyProducts').then((mod) => mod.SectionMyProducts), { ssr: false })

type TypeHomePage = {
  data: any,
  staticText: any,
  isAuthorize?: any,
  translations?: any
}

const Main = styled.main`
`

export type productDetailType = [
  TypeProductDetailVariant,
  Dispatch<SetStateAction<TypeProductDetailVariant>>
]

export async function getStaticProps({ locale }) {
  const dictionary = getTranslations(locale, ['home', 'account', 'shop', 'product_page'])

  const [[data, error], [layout, layoutError]] = await Promise.all([
    await fetchAPI('home'),
    await fetchAPI('layout')
  ])

  if (error || layoutError) return { notFound: true }

  return {
    props: {
      data,
      translations: dictionary,
      layout
    }
  }
}

const HomePage: NextPage<TypeHomePage> & { Layout?:FC } = memo(({ data }) => {
  const { seo, banners: [ header, ...product_highlighted ], products } = data
  const { locale } = useRouter()

  // if !userData then display only publicHome page components
  const userData = null
  console.log(`🟠 userData ? ${!!userData} -> Displaying ${userData ? 'USER' : 'PUBLIC'} homepage`)
  console.log('locale:', locale)

  const [productDetailVisible, setProductDetailVisible] = useState(false)
  const [productDetailed, setProductDetailed]:productDetailType = useState(null)
  const displayProductDetail = () => {
    if(!productDetailVisible) setProductDetailVisible(true)
  }

  const hideProductDetail = () => {
    if(productDetailVisible) setProductDetailVisible(false)
    // const url = PENDING GET PROPER URL FOR THE WINDOW STATE
    // window.history.pushState('', '', url)
  }

  return (
    <>
      {locale === 'en-EU' ?
        <SectionStorePicker />
        :
        <>
          <HeadTag seo={seo} jsonld={{data: data, pageType: 'home'} } pageType='home' />
          {header && <HeaderHome data={header} />}
          <Main>
            {userData && <DynamicSectionMyProducts data={'hardcoded'}/>}
            {!userData && <Subscribe/>}
            {products.length ? <SectionBestseller data={products} setProductDetailed={setProductDetailed} displayProductDetail={displayProductDetail} /> : <></>}
            {product_highlighted?.length ? <DynamicSectionHighlights data={product_highlighted} /> : <></>}
            {apiBrands.length && userData ? <BrandsSection brands={apiBrands} /> : <></>}
            <SectionProductDetail productDetailVisible={productDetailVisible} hideProductDetail={hideProductDetail} variant={productDetailed} className='not-in-grid' />
          </Main>
        </>
      }
    </>
  )
})

HomePage.Layout = LayoutEmpty
export default HomePage
